import {Button, Spin, Table} from 'antd';
import {CoursesService} from './CoursesService';
import {useEffect, useState} from 'react';
import {Gender, Course} from '../../graphql/API';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment from 'moment';

const coursesService = new CoursesService();
export const CoursesTable = () => {
  const [courses, setCourses] = useState<Array<Course> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    coursesService
      .getCourses()
      .then((resp) => {
        setIsLoading(false);
        console.log('courses: ', resp);
        setCourses(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: 'Plan ID',
      dataIndex: ['plan', 'id'],
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
      render: (planId: string) => <Link to={'/plans/' + planId}>{planId}</Link>,
    },
    {
      title: 'Level',
      defaultSortOrder: 'ascend',
      dataIndex: ['level', 'name'],
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
    },
    {
      title: 'Language',
      dataIndex: ['language', 'id'],
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
    },

    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'course ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
    },
  ];

  return (
    <>
      <Button
        size={'large'}
        style={{float: 'right', margin: 20}}
        onClick={() => alert('In progress')}>
        Create
      </Button>
      {!isError && !isLoading && (
        <Table
          expandRowByClick={true}
          onExpand={() => console.log('on!')}
          //@ts-ignore
          columns={columns}
          dataSource={courses}
          onChange={onChange}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
