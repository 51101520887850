import {ICoursesService} from './ICoursesService';
import {CoursesRepository} from './CoursesRepository';

export class CoursesService implements ICoursesService {
  coursesRepository: CoursesRepository;
  constructor() {
    this.coursesRepository = new CoursesRepository();
  }

  async getCourses(): Promise<any> {
    let stds = await this.coursesRepository.getCourses();
    //console.log('stds-->', stds);
    return stds;
  }
  /*async getCourseById(courseId: string): Promise<Course> {
    let course = await this.coursesRepository.getCourseById(courseId);
    console.log('course from service ->', course);
    return course;
  }*/
}
