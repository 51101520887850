import {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Button, Col, Collapse, Row, Spin, Typography} from 'antd';
import {Question, Test} from '../../graphql/API';
import {TestsService} from './TestsService';
import {getFileUrlByKey} from '../../utils';

const testsService = new TestsService();

export const TestDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [test, setTest] = useState<Test | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {testId: string} = useParams();

  const {Title, Text} = Typography;
  const {Panel} = Collapse;
  useEffect(() => {
    testsService
      .getTestById(params.testId)
      .then((resp) => {
        console.log('the->', resp);
        setIsLoading(false);
        setTest(resp);
      })
      .catch((error) => {
        console.log('cat->', error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Test </Title>
      <Button size={'large'} style={{float: 'right'}}>
        <Link
          // @ts-ignore
          to={{
            pathname: '/tests/' + test?.id + '/edit',
            state: {test: test},
          }}>
          Edit
        </Link>
      </Button>
      {!isError && !isLoading && (
        <div style={{margin: '20px'}}>
          <Text style={{display: 'block'}} strong={true}>
            Created at
          </Text>
          <Text>{test?.createdAt}</Text>

          <Text style={{display: 'block'}}>
            <Text style={{fontWeight: 'bold'}}>Description:</Text>
            {test?.description}
          </Text>
          <Text style={{display: 'block'}}>
            <Text style={{fontWeight: 'bold'}}>Summary:</Text> {test?.summary}
          </Text>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{display: 'block'}}
            href={test?.asset?.thumbnailUri!}>
            Thumbnail url
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{display: 'block'}}
            href={test?.asset?.fileUri!}>
            Content url
          </a>
          <Collapse
            defaultActiveKey={['1']}
            onChange={(e) => console.log('changed e:', e)}>
            {test?.questions?.map((question: Question | null) => {
              return (
                <Panel
                  key={question?.id!}
                  header={'question: ' + question?.name}>
                  <Text style={{display: 'block'}}>
                    <Text style={{fontWeight: 'bold'}}>Text: </Text>
                    {question?.text}
                  </Text>
                  <Text style={{display: 'block'}}>
                    <Text style={{fontWeight: 'bold'}}>Summary: </Text>
                    {question?.summary}
                  </Text>
                  <Text style={{display: 'block'}}>
                    <Text style={{fontWeight: 'bold'}}>Description: </Text>
                    {question?.description}
                  </Text>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{display: 'block'}}
                    href={question?.asset?.thumbnailUri!}>
                    Thumbnail url
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{display: 'block'}}
                    href={question?.asset?.fileUri!}>
                    Content url
                  </a>
                  {question?.correctAsset?.fileUri && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{display: 'block'}}
                      href={question?.correctAsset?.fileUri}>
                      Correct answer
                    </a>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Test fetching error ⚠️</Title>}
    </div>
  );
};
