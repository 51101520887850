import {Asset, AssetType, Question, Test} from '../graphql/API';

const PHOTO_TYPES = ['png', 'jpg', 'jpeg'];
export const validateCreateTest = (test: Test) => {
  if (test.name?.length === 0) {
    return {status: false, message: 'Please enter test name'};
  }
  if (test.summary?.length === 0) {
    return {status: false, message: 'Please enter test summary'};
  }
  if (test.description?.length === 0) {
    return {status: false, message: 'Please enter test description'};
  }

  let testAssetValidation = validateCreateAsset(test?.asset!);
  if (!testAssetValidation.status) {
    return {
      status: false,
      message: 'Test asset:\n' + testAssetValidation.message,
    };
  }
  let questionsValidationResults = test.questions?.map((question, index) => {
    //@ts-ignore
    return validateQuestion(question, index);
  });

  let errorsList = questionsValidationResults?.filter((r) => !r.status);

  if (errorsList?.length !== 0) {
    // @ts-ignore
    return errorsList[0];
  } else {
    return {status: true, message: 'Valid test'};
  }
};

export const validateCreateAsset = (asset: Asset) => {
  switch (asset?.type) {
    case AssetType.AUDIO:
      return validateAudioAsset(asset);
    case AssetType.PHOTO:
      return validatePhotoAsset(asset);
    case AssetType.VIDEO:
      return validateVideoAsset(asset);
    default:
      return {status: false, message: 'Please select asset type'};
  }
};

const validateAudioAsset = (asset: Asset) => {
  let fileUriArray = asset.fileUri?.split('.');
  //@ts-ignore
  let fileExtension = fileUriArray[fileUriArray.length - 1];
  if (fileExtension.toLocaleLowerCase() === 'aac') {
    return {status: true, message: 'Valid audio Asset'};
  } else {
    return {status: false, message: 'Audio must be AAC'};
  }
};

const validateVideoAsset = (asset: Asset) => {
  let fileUriArray = asset.fileUri?.split('.');
  //@ts-ignore
  let fileExtension = fileUriArray[fileUriArray.length - 1].toLocaleLowerCase();

  let thumbnailUriArray = asset.thumbnailUri?.split('.');
  //@ts-ignore
  let thumbnailExtension = thumbnailUriArray[
    //@ts-ignore
    thumbnailUriArray.length - 1
  ].toLocaleLowerCase();

  if (!PHOTO_TYPES.includes(thumbnailExtension)) {
    return {status: false, message: 'Thumbnail must be jpg, png or jpeg'};
  }
  if (fileExtension !== 'mp4') {
    return {status: false, message: 'Video must be mp4'};
  }
  return {status: true, message: 'Valid video asset'};
};

const validatePhotoAsset = (asset: Asset) => {
  let fileUriArray = asset.fileUri?.split('.');
  //@ts-ignore
  let fileExtension = fileUriArray[fileUriArray.length - 1].toLocaleLowerCase();

  let thumbnailUriArray = asset.thumbnailUri?.split('.');
  //@ts-ignore
  let thumbnailExtension = thumbnailUriArray[
    //@ts-ignore
    thumbnailUriArray.length - 1
  ].toLocaleLowerCase();

  if (!PHOTO_TYPES.includes(thumbnailExtension)) {
    return {
      status: false,
      message: 'Thumbnail extension must be jpg, png or jpeg',
    };
  }
  if (!PHOTO_TYPES.includes(fileExtension)) {
    return {status: false, message: 'File extension must be jpg, png or jpeg'};
  }
  return {status: true, message: 'Valid photo asset'};
};

export const validateQuestion = (question: Question, i: number) => {
  if (question.name?.length === 0) {
    return {
      status: false,
      message: 'Question ' + i + ' name:\n Please enter question name',
    };
  }
  if (question.summary?.length === 0) {
    return {
      status: false,
      message: 'Question ' + i + ' summary:\n Please enter question summary',
    };
  }
  if (question.description?.length === 0) {
    return {
      status: false,
      message:
        'Question ' + i + ' description:\n Please enter question description',
    };
  }

  if (question.text?.length === 0) {
    return {
      status: false,
      message: 'Question ' + i + ' text:\n Please enter question text',
    };
  }

  let questionAssetValidation = validateCreateAsset(question.asset!);
  if (!questionAssetValidation.status) {
    return {
      status: false,
      message: 'Question ' + i + ' asset:\n' + questionAssetValidation.message,
    };
  }

  if (question.correctAsset?.fileUri?.length !== 0) {
    let questionCorrectAssetValidation = validateCreateAsset(
      question.correctAsset!,
    );
    if (!questionCorrectAssetValidation.status) {
      return {
        status: false,
        message:
          'Question ' +
          i +
          ' correct asset:\n' +
          questionCorrectAssetValidation.message,
      };
    }
  }

  return {
    status: true,
    message: 'Question ' + i + ' is valid',
  };
};
