import {ICoursesRepository} from './ICoursesRepository';
import {API, graphqlOperation} from 'aws-amplify';

import {Course} from '../../graphql/API';
import {courses} from './CoursesQueries';

export class CoursesRepository implements ICoursesRepository {
  async getCourses(): Promise<Course | any> {
    try {
      let response = await API.graphql(graphqlOperation(courses));
      // console.log('courses from repository:', response);
      // @ts-ignore
      return response.data?.courses.items;
    } catch (e) {
      console.log('getCourses Error->', e);
    }
  }
  /* async getCourseById(courseId: string): Promise<any> {
    let response: any = await API.graphql(
      graphqlOperation(course, {id: courseId}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Fetch course Error: ', e);
      });
    return response.data?.course;
  }*/
}
