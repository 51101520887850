import {API, graphqlOperation} from 'aws-amplify';

import {ITestsRepository} from './ITestsRepository';
import {tests, test} from './TestsQueries';
import {createTest} from './TestMutations';

export class TestsRepository implements ITestsRepository {
  async getTests(): Promise<any> {
    try {
      let response = await API.graphql(graphqlOperation(tests));
      // console.log('Tests->', response);
      // @ts-ignore
      return response.data?.tests.items;
    } catch (e) {
      console.log('getTests repository Error:', e);
    }
  }

  async getTestById(testId: string): Promise<any> {
    let response: any = await API.graphql(graphqlOperation(test, {id: testId})) // @ts-ignore
      .catch((e) => {
        console.log('Fetch test Error: ', e);
      });
    return response.data?.test;
  }

  async createTest(test: any): Promise<any> {
    let createTestResponse: any = await API.graphql(
      graphqlOperation(createTest, {input: test}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Create Test Error: ', e);
        throw new Error(e);
      });

    return createTestResponse.data?.createTest;
  }
}
