import {API, graphqlOperation} from 'aws-amplify';

import {IPackagesRepository} from './IPackagesRepository';
import {packages} from './PackagesQueries';

export class PackagesRepository implements IPackagesRepository {
  async getPackages(): Promise<any> {
    try {
      let response = await API.graphql(graphqlOperation(packages));
      // console.log('Packages->', response);
      // @ts-ignore
      return response.data?.packages.items;
    } catch (e) {
      console.log('getPackages repository Error:', e);
    }
  }
}
