/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8e454816-c70f-4be2-9cb7-70cf7c6a8232",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_WIbe5FE1v",
    "aws_user_pools_web_client_id": "2fhpmuvtk0oflrnojcg68hrhme",
    "oauth": {
        "domain": "id.pikado.io.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://accounts.pikado.io/callback/,https://oauth.pstmn.io/v1/browser-callback",
        "redirectSignOut": "https://accounts.pikado.io/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://2nnmd4sggbegbfefzjuxfa7emi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "uploads.pikado.io",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
