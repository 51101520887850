import {Asset, AssetType} from '../graphql/API';
import {Input, Select} from 'antd';
import {useState} from 'react';
import Text from 'antd/es/typography/Text';

type CreateAssetBlockProps = {
  onChange: (asset: Asset) => void;
  defaultAssetType: AssetType;
};
export const CreateAssetBlock = ({
  onChange,
  defaultAssetType,
}: CreateAssetBlockProps) => {
  const [type, setType] = useState<AssetType>(defaultAssetType);
  const [thumbnailUri, setThumbnailUri] = useState<string>('');
  const [fileUri, setFileUri] = useState<string>('');

  return (
    <div>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text style={{display: 'block'}}>Asset type </Text>
        <Select
          value={type}
          showSearch
          style={{width: 200}}
          placeholder="Select a asset type"
          optionFilterProp="children"
          onChange={(selectedAssetType: AssetType) => {
            let tempAsset = {
              type: selectedAssetType,
              thumbnailUri: thumbnailUri,
              fileUri: fileUri,
            };
            setType(selectedAssetType);
            //@ts-ignore
            onChange(tempAsset);
          }}>
          <Select.Option value={AssetType.VIDEO}>
            {AssetType.VIDEO}
          </Select.Option>
          <Select.Option value={AssetType.PHOTO}>
            {AssetType.PHOTO}
          </Select.Option>
          <Select.Option value={AssetType.AUDIO}>
            {AssetType.AUDIO}
          </Select.Option>
        </Select>
      </div>
      {type !== AssetType.AUDIO && (
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
          <Text>Thumbnail url </Text>
          <Input
            onChange={(e) => {
              let tempAsset = {
                type: type,
                thumbnailUri: e.target.value,
                fileUri: fileUri,
              };
              setThumbnailUri(e.target.value);
              //@ts-ignore
              onChange(tempAsset);
            }}
            placeholder="Basic usage"
          />
        </div>
      )}
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text>File url </Text>
        <Input
          onChange={(e) => {
            let tempAsset = {
              type: type,
              thumbnailUri: thumbnailUri,
              fileUri: e.target.value,
            };
            setFileUri(e.target.value);
            //@ts-ignore
            onChange(tempAsset);
          }}
          placeholder="Basic usage"
        />
      </div>
    </div>
  );
};
