import {useEffect, useState} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {Button, Select, Spin, Typography, Collapse} from "antd";

import {Plan, Question, Test} from "../../graphql/API";
import {PlansService} from "./PlansService";
import {getFileUrlByKey} from "../../utils";
import {TestsService} from "../Test";

const plansService = new PlansService();
const testsService = new TestsService();
export const PlanEdit = () => {
  const history = useHistory();

  const [tests, setTests] = useState<Array<Test>>();
  const [plan, setPlan] = useState<Plan | undefined>(undefined);

  const [updatedFields, setUpdatedFields] = useState({});
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {planId: string} = useParams();

  const {Panel} = Collapse;
  const {Title, Text} = Typography;

  useEffect(() => {
    testsService
      .getTests()
      .then((testsResponse) => {
        setTests(testsResponse);
      })
      .catch((e) => {
        alert("Fetching tests error");
        setIsLoading(false);
        setIsError(true);
      });
    plansService
      .getPlanById(params.planId)
      .then((resp) => {
        setIsLoading(false);
        setPlan(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const renderDay = (plan: Plan) => {
    return Object.entries(plan).map((dayObject) => {
      let key = dayObject[0];
      let value = dayObject[1];
      if (key.charAt(0) === "d") {
        return (
          <Panel header={key} key={key}>
            <Select
              mode="multiple"
              style={{width: "100%"}}
              placeholder="Please select"
              //@ts-ignore
              defaultValue={value.map((m) => m.name + "+" + m.id!)}
              onChange={(idWithNameList) => {
                let tempPlan = plan;
                let testIds = idWithNameList.map((e: any) => e.split("+")[1]);
                let selectedTests = tests?.filter((t) =>
                  testIds.includes(t.id),
                );
                //@ts-ignore
                tempPlan[key] = selectedTests;
                setPlan(tempPlan);

                /*let tempUpdatedFields = updatedFields;
                //@ts-ignore
                tempUpdatedFields[key] = testIds;
                setUpdatedFields(tempUpdatedFields);*/
              }}>
              {/* @ts-ignore */}
              {tests.map((k) => (
                <Select.Option value={k.name + "+" + k.id!}>
                  <p>{k.name}</p>
                </Select.Option>
              ))}
            </Select>
          </Panel>
        );
      }
    });
  };
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: "center"}}>Plan Edit</Title>
      {!isError && !isLoading && (
        <>
          <Collapse
            defaultActiveKey={"d1"}
            onChange={(e) => console.log("changed e:", e)}>
            {plan && renderDay(plan)}
          </Collapse>
        </>
      )}
      <div style={{width: "100%", textAlign: "center", marginTop: "20px"}}>
        <Button
          style={{}}
          size={"large"}
          onClick={async () => {
            let planObj = plan;
            let planId = plan?.id;
            let updatedAt = plan?.updatedAt;
            delete planObj?.id;
            delete planObj?.createdAt;
            delete planObj?.updatedAt;
            let newPlan = {};
            // @ts-ignore
            Object.entries(plan).map((dayObject) => {
              let key = dayObject[0];
              let value = dayObject[1];
              //@ts-ignore
              let ids = value.map((v) => v.id);
              console.log("ids->", ids);
              //@ts-ignore
              newPlan[key] = ids;
            });

            await plansService
              //@ts-ignore
              .updatePlan(planId!, updatedAt!, newPlan)
              .then((e) => {
                console.log("planId->", planId);
                alert("Plan edited");
                history.push("/plans/" + planId);
              })
              .catch((er) => {
                alert("Plan edit failed");
                console.log("updating plan error ->", er);
              });
          }}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Spin size="large" style={{margin: "auto", width: "100%"}} />
      )}
      {isError && <Title>Plan fetching error ⚠️</Title>}
    </div>
  );
};
