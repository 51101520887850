import {IQuestionsService} from './IQuestionsService';
import {QuestionsRepository} from './QuestionsRepository';
import {Question} from '../../graphql/API';
import {AssetsRepository} from '../Asset/AssetsRepository';

export class QuestionsService implements IQuestionsService {
  questionsRepository: QuestionsRepository;
  assetsRepository: AssetsRepository;
  constructor() {
    this.questionsRepository = new QuestionsRepository();
    this.assetsRepository = new AssetsRepository();
  }
  async createQuestion(question: Question): Promise<any> {
    let questionObj = {
      name: question.name,
      summary: question.summary,
      description: question.description,
      text: question.text,
      correctAssetId: '',
      assetId: '',
      programType: question.programType,
      choices: [],
      keywords: [],
    };
    questionObj.assetId = await this.assetsRepository.createAsset(
      question.asset!,
    );
    if (question.correctAsset?.fileUri?.length !== 0) {
      questionObj.correctAssetId = await this.assetsRepository.createAsset(
        question.correctAsset!,
      );
    }

    console.log('questionObj to create ->', questionObj);
    return await this.questionsRepository.createQuestion(questionObj);
  }
}
