export const students = /* GraphQL */ `
  query Students($filter: StudentsFilter, $limit: Int, $nextToken: String) {
    students(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        birthDate
        language {
          id
          country
        }
        age
        user {
          id
          parent {
            id
            name
          }
        }
        gender
        notes
        facebookProfileUrl
      }
      nextToken
      scannedCount
    }
  }
`;

export const student = /* GraphQL */ `
  query student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      name
      birthDate
      age
      user {
        id
        parent {
          id
          name
        }
      }
      gender
      enrollments {
        id
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
    }
  }
`;
