import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Input, Modal, Select, Spin, Tabs, Typography} from 'antd';

import {CreateAssetBlock} from '../../components';
import {
  Asset,
  AssetType,
  ProgramType,
  Question,
  TestTypes,
} from '../../graphql/API';
import {TestsService} from './TestsService';
import {validateCreateTest} from '../../utils/Validators';

const testService = new TestsService();
export const TestCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const {TabPane} = Tabs;
  const emptyTestPane = {
    name: '',
    //@ts-ignore
    correctAsset: {
      type: AssetType.AUDIO,
      thumbnailUri: '',
      fileUri: '',
    },
    //@ts-ignore
    asset: {
      type: AssetType.PHOTO,
      thumbnailUri: '',
      fileUri: '',
    },
    programType: ProgramType.LANGUAGE,
    summary: 'Record with your own voice',
    description:
      'Press the photo or the words to listen to the pronunciation and then press the record button to record it with your own voice.',
    text: '',
  };

  //@ts-ignore
  const [panes, setPanes] = useState<Array<Question>>([emptyTestPane]);
  const [activeKey, setActiveKey] = useState<any>(0);

  const [testName, setTestName] = useState<string>('');
  const [testType, setTestType] = useState<TestTypes | undefined>(
    TestTypes.QUIZ,
  );
  // @ts-ignore
  const [testAsset, setTestAsset] = useState<Asset | undefined>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });
  const [testDescription, setTestDescription] = useState<string>(
    'Watch the video at least 2 times and after that record the questions’ answers.',
  );
  const [testSummary, setTestSummary] = useState<string>(
    'Watch carefully at least 2 times and answer the questions.',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {}, []);

  const onChange = (activeKey: string) => {
    setActiveKey(parseInt(activeKey));
  };

  const onEdit = (targetKey: any, action: any) => {
    //this[action](targetKey);
    switch (action) {
      case 'add':
        add();
        break;
      /*
      case 'remove':
        remove(parseInt(targetKey));
        break;
*/

      default:
        console.log('CASE DEFAULT');
    }
  };

  const add = () => {
    const activeKey = panes.length;
    console.log('panes.length->', panes.length);
    const newPanes = panes;
    // @ts-ignore
    newPanes.push(emptyTestPane);
    setPanes(newPanes);
    setActiveKey(activeKey);
  };
  /*

  const remove = (targetIndex: number) => {
    const newPanes = panes.filter((pane: any, index: number) => {
      return index !== targetIndex;
    });
    console.log('newPanes->', newPanes);
    console.log('newPanes.length->', newPanes.length);
    let lase = newPanes[newPanes.length - 1];
    console.log('lase->', lase);
    setPanes(newPanes);
    setActiveKey(newPanes.length);
  };
*/

  return (
    <>
      <div style={{padding: 10}}>
        <Title style={{textAlign: 'center'}}>Create test </Title>
        <>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test name </Text>
            <Input
              onChange={(e) => {
                setTestName(e.target.value);
              }}
              placeholder="Test name"
            />
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Test type </Text>
            <Select
              value={testType}
              showSearch
              style={{width: 200}}
              placeholder="Select a test type"
              optionFilterProp="children"
              onChange={(selectedTestType: TestTypes) => {
                setTestType(selectedTestType);
              }}>
              <Select.Option value={TestTypes.QUIZ}>
                {TestTypes.QUIZ}
              </Select.Option>
              <Select.Option value={TestTypes.PLACEMENT_TEST}>
                {TestTypes.PLACEMENT_TEST}
              </Select.Option>
            </Select>
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test description </Text>
            <Input
              value={testDescription}
              onChange={(e) => {
                setTestDescription(e.target.value);
              }}
              placeholder="Test description"
            />
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test summary </Text>
            <Input
              value={testSummary}
              onChange={(e) => {
                setTestSummary(e.target.value);
              }}
              placeholder="Test summary"
            />
          </div>

          <CreateAssetBlock
            defaultAssetType={AssetType.VIDEO}
            // @ts-ignore TODO be checked
            onChange={(selectedAsset) => setTestAsset(selectedAsset)}
          />
        </>

        <Tabs
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey.toString()}
          onEdit={onEdit}>
          {panes.map((pane, index) => (
            <TabPane
              tab={'question_' + index}
              key={index}
              closable={false}
              style={{
                border: '2px solid gray',
                padding: '10px',
              }}>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question name</Text>
                <Input
                  onChange={(e) => {
                    let tempPanes = panes;
                    tempPanes[index].name = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question name"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question Summary</Text>
                <Input
                  value={panes[index].summary}
                  onChange={(e) => {
                    let tempPanes = panes;
                    tempPanes[index].summary = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question Summary"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question description</Text>
                <Input
                  value={panes[index].description}
                  onChange={(e) => {
                    let tempPanes = panes;
                    tempPanes[index].description = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question description"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question text</Text>
                <Input
                  onChange={(e) => {
                    let tempPanes = panes;
                    tempPanes[index].text = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question text"
                />
              </div>

              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question asset
                </Text>
                <CreateAssetBlock
                  defaultAssetType={AssetType.PHOTO}
                  // @ts-ignore TODO be checked
                  onChange={(asset) => {
                    let tempPanes = panes;
                    tempPanes[index].asset = asset;
                    setPanes(tempPanes);
                  }}
                />
              </div>
              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question correct answer asset file url
                </Text>
                <Input
                  onChange={(e) => {
                    let tempAsset = {
                      fileUri: e.target.value,
                      type: AssetType.AUDIO,
                      thumbnailUri: '',
                    };
                    let tempPanes = panes;
                    // @ts-ignore
                    tempPanes[index].correctAsset = tempAsset;
                    setPanes(tempPanes);
                  }}
                  placeholder="Correct answer url"
                />
              </div>
            </TabPane>
          ))}
        </Tabs>

        <Button
          onClick={async () => {
            setIsLoading(true);
            let test = {
              questions: panes,
              type: testType,
              name: testName,
              description: testDescription,
              summary: testSummary,
              asset: testAsset,
            };
            /*   let testAssetValidation = validateCreateAsset(testAsset!);
            if (!testAssetValidation.status) {
              alert('Test asset:\n' + testAssetValidation.message);
              setIsLoading(false);
              return;
            }*/

            test.questions.forEach((q, i) => {});

            // @ts-ignore
            let testValidationResult = validateCreateTest(test);
            if (testValidationResult?.status) {
              await testService
                .createTest(test)
                .catch((e) => {
                  setIsLoading(true);
                  alert('Error:');
                  console.log('testService.createTest Error:', e);
                })
                .then((response) => {
                  console.log('response->', response);
                  alert('Test with ID: ' + response?.id + ' created');
                  history.push('/tests');
                });
            } else {
              console.log(
                'inside validateCreateTest else: ',
                testValidationResult,
              );
              alert(testValidationResult.message);
              setIsLoading(false);
            }
          }}>
          Create
        </Button>
      </div>
      <Modal
        okButtonProps={{hidden: true}}
        cancelButtonProps={{hidden: true}}
        visible={isLoading}
        closable={true}>
        <div>
          <Spin size="large" style={{margin: 'auto', width: '100%'}} />
          <p style={{textAlign: 'center'}}>Loading...</p>
        </div>
      </Modal>
    </>
  );
};
