import {Storage} from 'aws-amplify';

export const getFileUrlByKey = async (key: string): Promise<any> => {
  return await Storage.get(key)
    .catch((error) => {
      console.log('getFileUrlByKey Error:', error);
      return undefined;
    })
    .then((response) => {
      return response;
    });
};
