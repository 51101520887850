export const plan = /* GraphQL */ `
  query plan($id: String!) {
    plan(id: $id) {
      id
      createdAt
      updatedAt
      d1 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d2 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d3 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d4 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d5 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d6 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d7 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d8 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d9 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d10 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d11 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d12 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d13 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d14 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d15 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d16 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d17 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d18 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d19 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d20 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d21 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d22 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d23 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d24 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d25 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d26 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d27 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d28 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d29 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      d30 {
        id
        createdAt
        updatedAt
        questions {
          id
          createdAt
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          updatedAt
          name
          correctAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          choices
          programType
          keywords
          summary
          description
          text
        }
        type
        name
        description
        summary
        asset {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
    }
  }
`;
