/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AnswerUpsert = {
  studentId: string,
  questionId: string,
  enrollmentId?: string | null,
  choice?: number | null,
  audioUri?: string | null,
  text?: string | null,
};

export type Answer = {
  __typename: "Answer",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  student?: Student,
  question?: Question,
  enrollment?: Enrollment,
  choice?: number | null,
  audioUri?: string | null,
  text?: string | null,
  score?: number | null,
  analysis?: string | null,
};

export type Node = {
  __typename: "Node",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type Student = {
  __typename: "Student",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  user?: User,
  school?: School,
  name?: string,
  birthDate?: string | null,
  age?: number,
  gender?: Gender,
  language?: Language,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  enrollments?:  Array<Enrollment | null > | null,
  memberships?:  Array<Membership | null > | null,
};

export type User = {
  __typename: "User",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  cognitoUserId?: string,
  country?: string | null,
  state?: string | null,
  whatsappNumber?: string | null,
  timeZone?: string | null,
  parent?: Parent,
  students?:  Array<Student >,
};

export type Parent = {
  __typename: "Parent",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  user?: User,
  name?: string,
  gender?: Gender,
};

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NONE = "NONE",
}


export type School = {
  __typename: "School",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  nameArabic?: string,
  nameEnglish?: string,
};

export type Language = {
  __typename: "Language",
  id?: string,
  // should be <language>_<country>
  createdAt?: string,
  updatedAt?: string,
  language?: string | null,
  country?: string | null,
};

export type Enrollment = {
  __typename: "Enrollment",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  student?: Student,
  package?: Package,
  level?: Level,
  test?: Test,
  placementStatus?: Status,
  paymentStatus?: Status,
  startDate?: string | null,
  slots?:  Array<Slot | null > | null,
  answer?: Answer,
};

export type Package = {
  __typename: "Package",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  number?: number | null,
  onlyAI?: boolean,
  name?: string,
  sessionDuration?: number,
  program?: Program,
  price?: Price,
  description?: string,
  benefits?: string,
  promoAsset?: Asset,
  coverAsset?: Asset,
};

export type Program = {
  __typename: "Program",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string | null,
  packages?:  Array<Package >,
  test?: Test,
  courses?:  Array<Course >,
};

export type Test = {
  __typename: "Test",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  questions?:  Array<Question | null > | null,
  type?: TestTypes,
  name?: string,
  description?: string,
  summary?: string,
  asset?: Asset,
};

export type Question = {
  __typename: "Question",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string,
  correctAsset?: Asset,
  asset?: Asset,
  choices?: Array< string >,
  programType?: ProgramType,
  keywords?: Array< string >,
  summary?: string,
  description?: string,
  text?: string,
};

export type Asset = {
  __typename: "Asset",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  type?: AssetType | null,
  thumbnailUri?: string | null,
  fileUri?: string | null,
};

export enum AssetType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  PHOTO = "PHOTO",
}


export enum ProgramType {
  LANGUAGE = "LANGUAGE",
  PROGRAMMING = "PROGRAMMING",
}


export enum TestTypes {
  QUIZ = "QUIZ",
  PLACEMENT_TEST = "PLACEMENT_TEST",
}


export type Course = {
  __typename: "Course",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string | null,
  program?: Program,
  language?: Language,
  level?: Level,
  plan?: Plan,
};

export type Level = {
  __typename: "Level",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string,
  number?: number,
};

export type Plan = {
  __typename: "Plan",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  d1?:  Array<Test | null > | null,
  d2?:  Array<Test | null > | null,
  d3?:  Array<Test | null > | null,
  d4?:  Array<Test | null > | null,
  d5?:  Array<Test | null > | null,
  d6?:  Array<Test | null > | null,
  d7?:  Array<Test | null > | null,
  d8?:  Array<Test | null > | null,
  d9?:  Array<Test | null > | null,
  d10?:  Array<Test | null > | null,
  d11?:  Array<Test | null > | null,
  d12?:  Array<Test | null > | null,
  d13?:  Array<Test | null > | null,
  d14?:  Array<Test | null > | null,
  d15?:  Array<Test | null > | null,
  d16?:  Array<Test | null > | null,
  d17?:  Array<Test | null > | null,
  d18?:  Array<Test | null > | null,
  d19?:  Array<Test | null > | null,
  d20?:  Array<Test | null > | null,
  d21?:  Array<Test | null > | null,
  d22?:  Array<Test | null > | null,
  d23?:  Array<Test | null > | null,
  d24?:  Array<Test | null > | null,
  d25?:  Array<Test | null > | null,
  d26?:  Array<Test | null > | null,
  d27?:  Array<Test | null > | null,
  d28?:  Array<Test | null > | null,
  d29?:  Array<Test | null > | null,
  d30?:  Array<Test | null > | null,
  lessons?:  Array<Lesson | null > | null,
};

export type Lesson = {
  __typename: "Lesson",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string,
  summary?: Asset,
  lessonPDF?: Asset,
};

export type Price = {
  __typename: "Price",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  package?: Package,
  name?: string,
  price?: number,
  currency?: string,
  country?: string,
};

export enum Status {
  TODO = "TODO",
  DOING = "DOING",
  DONE = "DONE",
}


export type Slot = {
  __typename: "Slot",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string | null,
  sessionDuration?: number,
  availability?: Availability,
  program?: Program,
  level?: Level,
  startDateTime?: string,
  endDateTime?: string,
  status?: Status,
  limit?: number,
  current?: number,
};

export type Availability = {
  __typename: "Availability",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  instructor?: Instructor,
  startDateTime?: string,
  endDateTime?: string,
};

export type Instructor = {
  __typename: "Instructor",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  user?: User,
  name?: string,
  gender?: Gender,
  program?: Program,
  level?: Level,
};

export type Membership = {
  __typename: "Membership",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  student?: Student,
  group?: Group,
};

export type Group = {
  __typename: "Group",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  startDate?: string | null,
  name?: string,
  division?: GroupDivision,
  sessionDuration?: number,
  limit?: number,
  level?: Level,
  memberships?:  Array<Membership | null > | null,
  sessions?:  Array<Session | null > | null,
};

export enum GroupDivision {
  ODD = "ODD",
  EVEN = "EVEN",
}


export type Session = {
  __typename: "Session",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  name?: string,
  slot?: Slot,
  duration?: number,
  startDateTime?: string,
  endDateTime?: string,
  status?: Status,
  instructor?: Instructor,
  lesson?: Lesson,
  zoomUrl?: string | null,
};

export type AvailabilityUpsert = {
  instructorId: string,
  startDateTime: string,
  endDateTime: string,
};

export type CourseUpsert = {
  name?: string | null,
  levelId: string,
  languageId: string,
  planId: string,
  programId: string,
};

export type EnrollmentInsert = {
  studentId: string,
  packageId: string,
  levelId?: string | null,
  testId: string,
  placementStatus: Status,
  paymentStatus: Status,
  startDate?: string | null,
};

export type EnrollmentUpdate = {
  studentId?: string | null,
  packageId?: string | null,
  levelId?: string | null,
  testId?: string | null,
  placementStatus?: Status | null,
  paymentStatus?: Status | null,
  startDate?: string | null,
};

export type GroupUpsert = {
  name?: string | null,
  limit?: number | null,
  levelId?: string | null,
  sessionDuration: number,
};

export type InstructorUpsert = {
  userId: string,
  name: string,
  gender: Gender,
  programId: string,
  levelId: string,
};

export type LanguageUpsert = {
  language: string,
  country: string,
};

export type LessonUpsert = {
  name: string,
};

export type LevelUpsert = {
  name: string,
  number: number,
};

export type AssetUpsert = {
  type?: AssetType | null,
  thumbnailUri?: string | null,
  fileUri?: string | null,
};

export type MembershipUpsert = {
  studentId: string,
  groupId: string,
};

export type MembershipUpsertFromSlot = {
  slotId: string,
  enrollmentId: string,
};

export type PackageUpsert = {
  programId: string,
  name: string,
  sessionDuration: number,
  description: string,
  onlyAI: boolean,
  benefits: string,
  promoAssetId: string,
  coverAssetId: string,
  number?: number | null,
};

export type PlanUpsert = {
  d1: Array< string >,
  d2: Array< string >,
  d3: Array< string >,
  d4: Array< string >,
  d5: Array< string >,
  d6: Array< string >,
  d7: Array< string >,
  d8: Array< string >,
  d9: Array< string >,
  d10: Array< string >,
  d11: Array< string >,
  d12: Array< string >,
  d13: Array< string >,
  d14: Array< string >,
  d15: Array< string >,
  d16: Array< string >,
  d17: Array< string >,
  d18: Array< string >,
  d19: Array< string >,
  d20: Array< string >,
  d21: Array< string >,
  d22: Array< string >,
  d23: Array< string >,
  d24: Array< string >,
  d25: Array< string >,
  d26: Array< string >,
  d27: Array< string >,
  d28: Array< string >,
  d29: Array< string >,
  d30: Array< string >,
  lessons?: Array< string | null > | null,
};

export type ParentUpsert = {
  userId: string,
  name: string,
  gender: Gender,
};

export type PriceUpsert = {
  packageId: string,
  name: string,
  price: number,
  currency: string,
  country: string,
};

export type ProgramUpsert = {
  name: string,
  testId: string,
};

export type QuestionUpsert = {
  name: string,
  summary: string,
  description: string,
  text: string,
  correctAssetId?: string | null,
  assetId: string,
  choices: Array< string >,
  programType: ProgramType,
  keywords: Array< string >,
};

export type SchoolUpsert = {
  nameArabic: string,
  nameEnglish: string,
};

export type SessionUpsert = {
  name: string,
  slotId: string,
  startDateTime: string,
  duration: number,
  endDateTime: string,
  status: Status,
  instructorId?: string | null,
  lessonId?: string | null,
  zoomUrl?: string | null,
};

export type SlotUpsert = {
  name?: string | null,
  sessionDuration: number,
  availabilityId: string,
  programId: string,
  levelId: string,
  startDateTime: string,
  endDateTime: string,
  status: Status,
  limit: number,
  current: number,
};

export type StudentUpsert = {
  userId: string,
  name: string,
  birthDate?: string | null,
  age: number,
  gender: Gender,
  languageId: string,
  notes?: string | null,
  facebookProfileUrl?: string | null,
  schoolId?: string | null,
};

export type TestUpsert = {
  name: string,
  assetId?: string | null,
  questionIds: Array< string >,
  type: TestTypes,
  description: string,
  summary: string,
};

export type UserUpsert = {
  cognitoUserId: string,
  country?: string | null,
  state?: string | null,
  timeZone?: string | null,
  whatsappNumber?: string | null,
};

export type AvailabilitiesFilter = {
  instructorId?: TableReferenceFilter | null,
  startDateTime?: TableStringFilter | null,
  endDateTime?: TableStringFilter | null,
};

export type TableReferenceFilter = {
  ne?: string | null,
  eq?: string | null,
};

export type TableStringFilter = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type AvailabilitiesConnection = {
  __typename: "AvailabilitiesConnection",
  items?:  Array<Availability >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type Connection = {
  __typename: "Connection",
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type CoursesConnection = {
  __typename: "CoursesConnection",
  items?:  Array<Course >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type EnrollmentsConnection = {
  __typename: "EnrollmentsConnection",
  items?:  Array<Enrollment >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LessonsConnection = {
  __typename: "LessonsConnection",
  items?:  Array<Lesson >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type GroupsConnection = {
  __typename: "GroupsConnection",
  items?:  Array<Group >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type InstructorsConnection = {
  __typename: "InstructorsConnection",
  items?:  Array<Instructor >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LanguagesConnection = {
  __typename: "LanguagesConnection",
  items?:  Array<Language >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type LevelsConnection = {
  __typename: "LevelsConnection",
  items?:  Array<Level >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type MembershipsConnection = {
  __typename: "MembershipsConnection",
  items?:  Array<Membership >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ParentsConnection = {
  __typename: "ParentsConnection",
  items?:  Array<Parent >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PackagesConnection = {
  __typename: "PackagesConnection",
  items?:  Array<Package >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PricesConnection = {
  __typename: "PricesConnection",
  items?:  Array<Price >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type ProgramsConnection = {
  __typename: "ProgramsConnection",
  items?:  Array<Program >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type QuestionsConnection = {
  __typename: "QuestionsConnection",
  items?:  Array<Question >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SchoolsConnection = {
  __typename: "SchoolsConnection",
  items?:  Array<School >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SessionsConnection = {
  __typename: "SessionsConnection",
  items?:  Array<Session >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type SlotsConnection = {
  __typename: "SlotsConnection",
  items?:  Array<Slot >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type StudentsConnection = {
  __typename: "StudentsConnection",
  items?:  Array<Student >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type TestsConnection = {
  __typename: "TestsConnection",
  items?:  Array<Test >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type UsersConnection = {
  __typename: "UsersConnection",
  items?:  Array<User >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type AssetsConnection = {
  __typename: "AssetsConnection",
  items?:  Array<Asset >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type PlansConnection = {
  __typename: "PlansConnection",
  items?:  Array<Plan >,
  nextToken?: string | null,
  scannedCount?: number | null,
};

export type CoursesFilter = {
  name?: TableStringFilter | null,
  levelId?: TableReferenceFilter | null,
  languageId?: TableReferenceFilter | null,
  planId?: TableReferenceFilter | null,
};

export type EnrollmentsFilter = {
  studentId?: TableReferenceFilter | null,
  packageId?: TableReferenceFilter | null,
  levelId?: TableReferenceFilter | null,
};

export type GroupsFilter = {
  name?: TableStringFilter | null,
  division?: TableStringFilter | null,
  limit?: TableIntFilter | null,
  levelId?: TableReferenceFilter | null,
};

export type TableIntFilter = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  contains?: number | null,
  notContains?: number | null,
  between?: Array< number | null > | null,
};

export type InstructorsFilter = {
  userId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
};

export type LanguagesFilter = {
  language?: TableStringFilter | null,
  country?: TableStringFilter | null,
};

export type LessonsFilter = {
  name?: TableStringFilter | null,
  number?: TableIntFilter | null,
};

export type LevelsFilter = {
  name?: TableStringFilter | null,
  number?: TableIntFilter | null,
};

export type MembershipsFilter = {
  studentId?: TableReferenceFilter | null,
  programId?: TableReferenceFilter | null,
};

export type ParentsFilter = {
  userId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
};

export type PackagesFilter = {
  programId?: TableReferenceFilter | null,
};

export type PricesFilter = {
  packageId?: TableReferenceFilter | null,
};

export type ProgramsFilter = {
  name?: TableStringFilter | null,
};

export type QuestionsFilter = {
  name?: TableStringFilter | null,
  programType?: TableStringFilter | null,
};

export type SchoolsFilter = {
  nameEnglish?: TableStringFilter | null,
};

export type SessionsFilter = {
  slotId?: TableReferenceFilter | null,
  instructorId?: TableReferenceFilter | null,
  startDateTime?: TableStringFilter | null,
  endDateTime?: TableStringFilter | null,
};

export type SlotsFilter = {
  name?: TableStringFilter | null,
  availabilityId?: TableReferenceFilter | null,
  startDateTime?: TableStringFilter | null,
  endDateTime?: TableStringFilter | null,
  status?: TableIntFilter | null,
  limit?: TableIntFilter | null,
  current?: TableIntFilter | null,
};

export type StudentsFilter = {
  userId?: TableReferenceFilter | null,
  schoolId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
  age?: TableIntFilter | null,
  language?: TableStringFilter | null,
};

export type TestsFilter = {
  userId?: TableReferenceFilter | null,
  schoolId?: TableReferenceFilter | null,
  name?: TableStringFilter | null,
  age?: TableIntFilter | null,
  language?: TableStringFilter | null,
};

export type UsersFilter = {
  cognitoUserId?: TableReferenceFilter | null,
  country?: TableStringFilter | null,
  state?: TableStringFilter | null,
  timeZone?: TableStringFilter | null,
};

export type CreateAnswerMutationVariables = {
  input?: AnswerUpsert,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: AnswerUpsert,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  id?: string,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
  } | null,
};

export type CreateAvailabilityMutationVariables = {
  input?: AvailabilityUpsert,
};

export type CreateAvailabilityMutation = {
  createAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    },
    startDateTime: string,
    endDateTime: string,
  } | null,
};

export type UpdateAvailabilityMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: AvailabilityUpsert,
};

export type UpdateAvailabilityMutation = {
  updateAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    },
    startDateTime: string,
    endDateTime: string,
  } | null,
};

export type DeleteAvailabilityMutationVariables = {
  id?: string,
};

export type DeleteAvailabilityMutation = {
  deleteAvailability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    },
    startDateTime: string,
    endDateTime: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input?: CourseUpsert,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    plan?:  {
      __typename: "Plan",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateCourseMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: CourseUpsert,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    plan?:  {
      __typename: "Plan",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteCourseMutationVariables = {
  id?: string,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    plan?:  {
      __typename: "Plan",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateEnrollmentMutationVariables = {
  input?: EnrollmentInsert,
};

export type CreateEnrollmentMutation = {
  createEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};

export type UpdateEnrollmentMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: EnrollmentUpdate,
};

export type UpdateEnrollmentMutation = {
  updateEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};

export type DeleteEnrollmentMutationVariables = {
  id?: string,
};

export type DeleteEnrollmentMutation = {
  deleteEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};

export type CreateGroupMutationVariables = {
  input?: GroupUpsert,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: GroupDivision,
    sessionDuration: number,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateGroupMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: GroupUpsert,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: GroupDivision,
    sessionDuration: number,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } | null > | null,
  } | null,
};

export type DeleteGroupMutationVariables = {
  id?: string,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: GroupDivision,
    sessionDuration: number,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } | null > | null,
  } | null,
};

export type CreateInstructorMutationVariables = {
  input?: InstructorUpsert,
};

export type CreateInstructorMutation = {
  createInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
  } | null,
};

export type UpdateInstructorMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: InstructorUpsert,
};

export type UpdateInstructorMutation = {
  updateInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
  } | null,
};

export type DeleteInstructorMutationVariables = {
  id?: string,
};

export type DeleteInstructorMutation = {
  deleteInstructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
  } | null,
};

export type CreateLanguageMutationVariables = {
  input?: LanguageUpsert,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: LanguageUpsert,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  id?: string,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type CreateLessonMutationVariables = {
  input?: LessonUpsert,
};

export type CreateLessonMutation = {
  createLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    lessonPDF:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type UpdateLessonMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: LessonUpsert,
};

export type UpdateLessonMutation = {
  updateLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    lessonPDF:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type DeleteLessonMutationVariables = {
  id?: string,
};

export type DeleteLessonMutation = {
  deleteLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    lessonPDF:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type CreateLevelMutationVariables = {
  input?: LevelUpsert,
};

export type CreateLevelMutation = {
  createLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number: number,
  } | null,
};

export type UpdateLevelMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: LevelUpsert,
};

export type UpdateLevelMutation = {
  updateLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number: number,
  } | null,
};

export type DeleteLevelMutationVariables = {
  id?: string,
};

export type DeleteLevelMutation = {
  deleteLevel?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number: number,
  } | null,
};

export type CreateAssetMutationVariables = {
  input?: AssetUpsert,
};

export type CreateAssetMutation = {
  createAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
  } | null,
};

export type UpdateAssetMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: AssetUpsert,
};

export type UpdateAssetMutation = {
  updateAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
  } | null,
};

export type DeleteAssetMutationVariables = {
  id?: string,
};

export type DeleteAssetMutation = {
  deleteAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
  } | null,
};

export type CreateMembershipMutationVariables = {
  input?: MembershipUpsert,
};

export type CreateMembershipMutation = {
  createMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: GroupDivision,
      sessionDuration: number,
      limit: number,
    },
  } | null,
};

export type CreateMembershipFromSlotMutationVariables = {
  input?: MembershipUpsertFromSlot,
};

export type CreateMembershipFromSlotMutation = {
  createMembershipFromSlot?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: GroupDivision,
    sessionDuration: number,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } | null > | null,
  } | null,
};

export type DeleteMembershipMutationVariables = {
  id?: string,
};

export type DeleteMembershipMutation = {
  deleteMembership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: GroupDivision,
      sessionDuration: number,
      limit: number,
    },
  } | null,
};

export type CreatePackageMutationVariables = {
  input?: PackageUpsert,
};

export type CreatePackageMutation = {
  createPackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI: boolean,
    name: string,
    sessionDuration: number,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description: string,
    benefits: string,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    coverAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type UpdatePackageMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: PackageUpsert,
};

export type UpdatePackageMutation = {
  updatePackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI: boolean,
    name: string,
    sessionDuration: number,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description: string,
    benefits: string,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    coverAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type DeletePackageMutationVariables = {
  id?: string,
};

export type DeletePackageMutation = {
  deletePackage?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI: boolean,
    name: string,
    sessionDuration: number,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description: string,
    benefits: string,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    coverAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type CreatePlanMutationVariables = {
  input?: PlanUpsert,
};

export type CreatePlanMutation = {
  createPlan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    d1?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d2?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d3?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d4?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d5?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d6?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d7?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d8?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d9?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d10?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d11?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d12?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d13?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d14?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d15?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d16?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d17?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d18?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d19?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d20?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d21?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d22?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d23?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d24?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d25?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d26?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d27?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d28?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d29?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d30?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
  } | null,
};

export type UpdatePlanMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: PlanUpsert,
};

export type UpdatePlanMutation = {
  updatePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    d1?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d2?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d3?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d4?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d5?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d6?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d7?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d8?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d9?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d10?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d11?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d12?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d13?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d14?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d15?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d16?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d17?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d18?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d19?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d20?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d21?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d22?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d23?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d24?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d25?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d26?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d27?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d28?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d29?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d30?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
  } | null,
};

export type DeletePlanMutationVariables = {
  id?: string,
};

export type DeletePlanMutation = {
  deletePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    d1?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d2?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d3?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d4?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d5?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d6?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d7?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d8?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d9?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d10?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d11?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d12?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d13?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d14?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d15?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d16?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d17?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d18?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d19?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d20?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d21?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d22?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d23?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d24?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d25?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d26?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d27?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d28?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d29?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d30?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
  } | null,
};

export type CreateParentMutationVariables = {
  input?: ParentUpsert,
};

export type CreateParentMutation = {
  createParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type UpdateParentMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: ParentUpsert,
};

export type UpdateParentMutation = {
  updateParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type DeleteParentMutationVariables = {
  id?: string,
};

export type DeleteParentMutation = {
  deleteParent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type CreatePriceMutationVariables = {
  input?: PriceUpsert,
};

export type CreatePriceMutation = {
  createPrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type UpdatePriceMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: PriceUpsert,
};

export type UpdatePriceMutation = {
  updatePrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type DeletePriceMutationVariables = {
  id?: string,
};

export type DeletePriceMutation = {
  deletePrice?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type CreateProgramMutationVariables = {
  input?: ProgramUpsert,
};

export type CreateProgramMutation = {
  createProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
  } | null,
};

export type UpdateProgramMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: ProgramUpsert,
};

export type UpdateProgramMutation = {
  updateProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
  } | null,
};

export type DeleteProgramMutationVariables = {
  id?: string,
};

export type DeleteProgramMutation = {
  deleteProgram?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input?: QuestionUpsert,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary: string,
    description: string,
    text: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: QuestionUpsert,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary: string,
    description: string,
    text: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  id?: string,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary: string,
    description: string,
    text: string,
  } | null,
};

export type CreateSchoolMutationVariables = {
  input?: SchoolUpsert,
};

export type CreateSchoolMutation = {
  createSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    nameEnglish: string,
  } | null,
};

export type UpdateSchoolMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: SchoolUpsert,
};

export type UpdateSchoolMutation = {
  updateSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    nameEnglish: string,
  } | null,
};

export type DeleteSchoolMutationVariables = {
  id?: string,
};

export type DeleteSchoolMutation = {
  deleteSchool?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    nameEnglish: string,
  } | null,
};

export type CreateSessionMutationVariables = {
  input?: SessionUpsert,
};

export type CreateSessionMutation = {
  createSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    },
    duration: number,
    startDateTime: string,
    endDateTime: string,
    status: Status,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    zoomUrl?: string | null,
  } | null,
};

export type UpdateSessionMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: SessionUpsert,
};

export type UpdateSessionMutation = {
  updateSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    },
    duration: number,
    startDateTime: string,
    endDateTime: string,
    status: Status,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    zoomUrl?: string | null,
  } | null,
};

export type DeleteSessionMutationVariables = {
  id?: string,
};

export type DeleteSessionMutation = {
  deleteSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    },
    duration: number,
    startDateTime: string,
    endDateTime: string,
    status: Status,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    zoomUrl?: string | null,
  } | null,
};

export type CreateSlotMutationVariables = {
  input?: SlotUpsert,
};

export type CreateSlotMutation = {
  createSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    sessionDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDateTime: string,
      endDateTime: string,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    startDateTime: string,
    endDateTime: string,
    status: Status,
    limit: number,
    current: number,
  } | null,
};

export type UpdateSlotMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: SlotUpsert,
};

export type UpdateSlotMutation = {
  updateSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    sessionDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDateTime: string,
      endDateTime: string,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    startDateTime: string,
    endDateTime: string,
    status: Status,
    limit: number,
    current: number,
  } | null,
};

export type DeleteSlotMutationVariables = {
  id?: string,
};

export type DeleteSlotMutation = {
  deleteSlot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    sessionDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDateTime: string,
      endDateTime: string,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    startDateTime: string,
    endDateTime: string,
    status: Status,
    limit: number,
    current: number,
  } | null,
};

export type CreateStudentMutationVariables = {
  input?: StudentUpsert,
};

export type CreateStudentMutation = {
  createStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      nameEnglish: string,
    } | null,
    name: string,
    birthDate?: string | null,
    age: number,
    gender: Gender,
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type UpdateStudentMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: StudentUpsert,
};

export type UpdateStudentMutation = {
  updateStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      nameEnglish: string,
    } | null,
    name: string,
    birthDate?: string | null,
    age: number,
    gender: Gender,
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type DeleteStudentMutationVariables = {
  id?: string,
};

export type DeleteStudentMutation = {
  deleteStudent?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      nameEnglish: string,
    } | null,
    name: string,
    birthDate?: string | null,
    age: number,
    gender: Gender,
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type CreateTestMutationVariables = {
  input?: TestUpsert,
};

export type CreateTestMutation = {
  createTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    } | null > | null,
    type: TestTypes,
    name: string,
    description: string,
    summary: string,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type UpdateTestMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: TestUpsert,
};

export type UpdateTestMutation = {
  updateTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    } | null > | null,
    type: TestTypes,
    name: string,
    description: string,
    summary: string,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type DeleteTestMutationVariables = {
  id?: string,
};

export type DeleteTestMutation = {
  deleteTest?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    } | null > | null,
    type: TestTypes,
    name: string,
    description: string,
    summary: string,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type CreateUserMutationVariables = {
  input?: UserUpsert,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type UpdateUserMutationVariables = {
  id?: string,
  updatedAt?: string,
  input?: UserUpsert,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type AddUserStudentMutationVariables = {
  id?: string,
  updatedAt?: string,
  studentId?: string,
};

export type AddUserStudentMutation = {
  addUserStudent?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type AddUserParentMutationVariables = {
  id?: string,
  updatedAt?: string,
  parentId?: string,
};

export type AddUserParentMutation = {
  addUserParent?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type DeleteUserMutationVariables = {
  id?: string,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type AnswerQueryVariables = {
  id?: string,
};

export type AnswerQuery = {
  answer?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
  } | null,
};

export type AnswerByStudentIdQueryVariables = {
  studentId?: string,
};

export type AnswerByStudentIdQuery = {
  answerByStudentId?:  {
    __typename: "Answer",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    },
    enrollment?:  {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null,
    choice?: number | null,
    audioUri?: string | null,
    text?: string | null,
    score?: number | null,
    analysis?: string | null,
  } | null,
};

export type AvailabilityQueryVariables = {
  id?: string,
};

export type AvailabilityQuery = {
  availability?:  {
    __typename: "Availability",
    id: string,
    createdAt: string,
    updatedAt: string,
    instructor:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    },
    startDateTime: string,
    endDateTime: string,
  } | null,
};

export type AvailabilitiesQueryVariables = {
  filter?: AvailabilitiesFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AvailabilitiesQuery = {
  availabilities?:  {
    __typename: "AvailabilitiesConnection",
    items:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDateTime: string,
      endDateTime: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type CourseQueryVariables = {
  id?: string,
};

export type CourseQuery = {
  course?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    plan?:  {
      __typename: "Plan",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CourseByEnrollmentIdQueryVariables = {
  enrollmentId?: string,
};

export type CourseByEnrollmentIdQuery = {
  courseByEnrollmentId?:  {
    __typename: "Course",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    plan?:  {
      __typename: "Plan",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CoursesQueryVariables = {
  filter?: CoursesFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoursesQuery = {
  courses?:  {
    __typename: "CoursesConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type EnrollmentQueryVariables = {
  id?: string,
};

export type EnrollmentQuery = {
  enrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};

export type EnrollmentsQueryVariables = {
  filter?: EnrollmentsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EnrollmentsQuery = {
  enrollments?:  {
    __typename: "EnrollmentsConnection",
    items:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GroupQueryVariables = {
  id?: string,
};

export type GroupQuery = {
  group?:  {
    __typename: "Group",
    id: string,
    createdAt: string,
    updatedAt: string,
    startDate?: string | null,
    name: string,
    division: GroupDivision,
    sessionDuration: number,
    limit: number,
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    sessions?:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } | null > | null,
  } | null,
};

export type GroupLessonsQueryVariables = {
  groupId?: string,
};

export type GroupLessonsQuery = {
  groupLessons?:  {
    __typename: "LessonsConnection",
    items:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type GroupsQueryVariables = {
  filter?: GroupsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupsQuery = {
  groups?:  {
    __typename: "GroupsConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: GroupDivision,
      sessionDuration: number,
      limit: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type InstructorQueryVariables = {
  id?: string,
};

export type InstructorQuery = {
  instructor?:  {
    __typename: "Instructor",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
  } | null,
};

export type InstructorsQueryVariables = {
  filter?: InstructorsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InstructorsQuery = {
  instructors?:  {
    __typename: "InstructorsConnection",
    items:  Array< {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LanguageQueryVariables = {
  id?: string,
};

export type LanguageQuery = {
  language?:  {
    __typename: "Language",
    id: string,
    // should be <language>_<country>
    createdAt: string,
    updatedAt: string,
    language?: string | null,
    country?: string | null,
  } | null,
};

export type LanguagesQueryVariables = {
  filter?: LanguagesFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguagesQuery = {
  languages?:  {
    __typename: "LanguagesConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LessonQueryVariables = {
  id?: string,
};

export type LessonQuery = {
  lesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    summary?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    lessonPDF:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type LessonsQueryVariables = {
  filter?: LessonsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LessonsQuery = {
  lessons?:  {
    __typename: "LessonsConnection",
    items:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type LevelQueryVariables = {
  id?: string,
};

export type LevelQuery = {
  level?:  {
    __typename: "Level",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    number: number,
  } | null,
};

export type LevelsQueryVariables = {
  filter?: LevelsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LevelsQuery = {
  levels?:  {
    __typename: "LevelsConnection",
    items:  Array< {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type AssetQueryVariables = {
  id?: string,
};

export type AssetQuery = {
  asset?:  {
    __typename: "Asset",
    id: string,
    createdAt: string,
    updatedAt: string,
    type?: AssetType | null,
    thumbnailUri?: string | null,
    fileUri?: string | null,
  } | null,
};

export type MembershipQueryVariables = {
  id?: string,
};

export type MembershipQuery = {
  membership?:  {
    __typename: "Membership",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDate?: string | null,
      name: string,
      division: GroupDivision,
      sessionDuration: number,
      limit: number,
    },
  } | null,
};

export type MembershipsQueryVariables = {
  filter?: MembershipsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsQuery = {
  memberships?:  {
    __typename: "MembershipsConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ParentQueryVariables = {
  id?: string,
};

export type ParentQuery = {
  parent?:  {
    __typename: "Parent",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    name: string,
    gender: Gender,
  } | null,
};

export type ParentsQueryVariables = {
  filter?: ParentsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ParentsQuery = {
  parents?:  {
    __typename: "ParentsConnection",
    items:  Array< {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type PackageQueryVariables = {
  id?: string,
};

export type PackageQuery = {
  package?:  {
    __typename: "Package",
    id: string,
    createdAt: string,
    updatedAt: string,
    number?: number | null,
    onlyAI: boolean,
    name: string,
    sessionDuration: number,
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    price:  {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    },
    description: string,
    benefits: string,
    promoAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    coverAsset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type PackagesQueryVariables = {
  filter?: PackagesFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackagesQuery = {
  packages?:  {
    __typename: "PackagesConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type PlanQueryVariables = {
  id?: string,
};

export type PlanQuery = {
  plan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    d1?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d2?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d3?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d4?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d5?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d6?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d7?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d8?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d9?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d10?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d11?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d12?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d13?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d14?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d15?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d16?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d17?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d18?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d19?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d20?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d21?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d22?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d23?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d24?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d25?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d26?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d27?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d28?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d29?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    d30?:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null > | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null > | null,
  } | null,
};

export type PriceQueryVariables = {
  id?: string,
};

export type PriceQuery = {
  price?:  {
    __typename: "Price",
    id: string,
    createdAt: string,
    updatedAt: string,
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    name: string,
    price: number,
    currency: string,
    country: string,
  } | null,
};

export type PricesQueryVariables = {
  filter?: PricesFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricesQuery = {
  prices?:  {
    __typename: "PricesConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      price: number,
      currency: string,
      country: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type ProgramQueryVariables = {
  id?: string,
};

export type ProgramQuery = {
  program?:  {
    __typename: "Program",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    packages:  Array< {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    } >,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    courses:  Array< {
      __typename: "Course",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
  } | null,
};

export type ProgramsQueryVariables = {
  filter?: ProgramsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProgramsQuery = {
  programs?:  {
    __typename: "ProgramsConnection",
    items:  Array< {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type QuestionQueryVariables = {
  id?: string,
};

export type QuestionQuery = {
  question?:  {
    __typename: "Question",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    correctAsset?:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    } | null,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
    choices: Array< string >,
    programType: ProgramType,
    keywords: Array< string >,
    summary: string,
    description: string,
    text: string,
  } | null,
};

export type QuestionsQueryVariables = {
  filter?: QuestionsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsQuery = {
  questions?:  {
    __typename: "QuestionsConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SchoolQueryVariables = {
  id?: string,
};

export type SchoolQuery = {
  school?:  {
    __typename: "School",
    id: string,
    createdAt: string,
    updatedAt: string,
    nameArabic: string,
    nameEnglish: string,
  } | null,
};

export type SchoolsQueryVariables = {
  filter?: SchoolsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchoolsQuery = {
  schools?:  {
    __typename: "SchoolsConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      nameEnglish: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SessionQueryVariables = {
  id?: string,
};

export type SessionQuery = {
  session?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    slot:  {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    },
    duration: number,
    startDateTime: string,
    endDateTime: string,
    status: Status,
    instructor?:  {
      __typename: "Instructor",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
    } | null,
    zoomUrl?: string | null,
  } | null,
};

export type SessionsQueryVariables = {
  filter?: SessionsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsQuery = {
  sessions?:  {
    __typename: "SessionsConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      duration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      zoomUrl?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type SlotQueryVariables = {
  id?: string,
};

export type SlotQuery = {
  slot?:  {
    __typename: "Slot",
    id: string,
    createdAt: string,
    updatedAt: string,
    name?: string | null,
    sessionDuration: number,
    availability:  {
      __typename: "Availability",
      id: string,
      createdAt: string,
      updatedAt: string,
      startDateTime: string,
      endDateTime: string,
    },
    program:  {
      __typename: "Program",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
    },
    level:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    },
    startDateTime: string,
    endDateTime: string,
    status: Status,
    limit: number,
    current: number,
  } | null,
};

export type SlotsQueryVariables = {
  filter?: SlotsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SlotsQuery = {
  slots?:  {
    __typename: "SlotsConnection",
    items:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type StudentQueryVariables = {
  id?: string,
};

export type StudentQuery = {
  student?:  {
    __typename: "Student",
    id: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    },
    school?:  {
      __typename: "School",
      id: string,
      createdAt: string,
      updatedAt: string,
      nameArabic: string,
      nameEnglish: string,
    } | null,
    name: string,
    birthDate?: string | null,
    age: number,
    gender: Gender,
    language:  {
      __typename: "Language",
      id: string,
      // should be <language>_<country>
      createdAt: string,
      updatedAt: string,
      language?: string | null,
      country?: string | null,
    },
    notes?: string | null,
    facebookProfileUrl?: string | null,
    enrollments?:  Array< {
      __typename: "Enrollment",
      id: string,
      createdAt: string,
      updatedAt: string,
      placementStatus: Status,
      paymentStatus: Status,
      startDate?: string | null,
    } | null > | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
  } | null,
};

export type StudentsQueryVariables = {
  filter?: StudentsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StudentsQuery = {
  students?:  {
    __typename: "StudentsConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type TestQueryVariables = {
  id?: string,
};

export type TestQuery = {
  test?:  {
    __typename: "Test",
    id: string,
    createdAt: string,
    updatedAt: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      choices: Array< string >,
      programType: ProgramType,
      keywords: Array< string >,
      summary: string,
      description: string,
      text: string,
    } | null > | null,
    type: TestTypes,
    name: string,
    description: string,
    summary: string,
    asset:  {
      __typename: "Asset",
      id: string,
      createdAt: string,
      updatedAt: string,
      type?: AssetType | null,
      thumbnailUri?: string | null,
      fileUri?: string | null,
    },
  } | null,
};

export type TestsQueryVariables = {
  filter?: TestsFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TestsQuery = {
  tests?:  {
    __typename: "TestsConnection",
    items:  Array< {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type UserQueryVariables = {
  id?: string,
};

export type UserQuery = {
  user?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type UsersQueryVariables = {
  filter?: UsersFilter | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersQuery = {
  users?:  {
    __typename: "UsersConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt: string,
      updatedAt: string,
      cognitoUserId: string,
      country?: string | null,
      state?: string | null,
      whatsappNumber?: string | null,
      timeZone?: string | null,
    } >,
    nextToken?: string | null,
    scannedCount?: number | null,
  } | null,
};

export type MeQuery = {
  me?:  {
    __typename: "User",
    id: string,
    createdAt: string,
    updatedAt: string,
    cognitoUserId: string,
    country?: string | null,
    state?: string | null,
    whatsappNumber?: string | null,
    timeZone?: string | null,
    parent?:  {
      __typename: "Parent",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      gender: Gender,
    } | null,
    students:  Array< {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    } >,
  } | null,
};

export type OnAddEnrollmentSubscription = {
  onAddEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};

export type OnUpdateEnrollmentSubscription = {
  onUpdateEnrollment?:  {
    __typename: "Enrollment",
    id: string,
    createdAt: string,
    updatedAt: string,
    student:  {
      __typename: "Student",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      birthDate?: string | null,
      age: number,
      gender: Gender,
      notes?: string | null,
      facebookProfileUrl?: string | null,
    },
    package:  {
      __typename: "Package",
      id: string,
      createdAt: string,
      updatedAt: string,
      number?: number | null,
      onlyAI: boolean,
      name: string,
      sessionDuration: number,
      description: string,
      benefits: string,
    },
    level?:  {
      __typename: "Level",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      number: number,
    } | null,
    test?:  {
      __typename: "Test",
      id: string,
      createdAt: string,
      updatedAt: string,
      type: TestTypes,
      name: string,
      description: string,
      summary: string,
    } | null,
    placementStatus: Status,
    paymentStatus: Status,
    startDate?: string | null,
    slots?:  Array< {
      __typename: "Slot",
      id: string,
      createdAt: string,
      updatedAt: string,
      name?: string | null,
      sessionDuration: number,
      startDateTime: string,
      endDateTime: string,
      status: Status,
      limit: number,
      current: number,
    } | null > | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      createdAt: string,
      updatedAt: string,
      choice?: number | null,
      audioUri?: string | null,
      text?: string | null,
      score?: number | null,
      analysis?: string | null,
    } | null,
  } | null,
};
