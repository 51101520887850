import {API, graphqlOperation} from 'aws-amplify';

import {IQuestionsRepository} from './IQuestionsRepository';
import {createQuestion} from './QuestionsMutations';

export class QuestionsRepository implements IQuestionsRepository {
  async createQuestion(question: any): Promise<any> {
    console.log('question to creaaaa->', question);
    let createQuestionResponse: any = await API.graphql(
      graphqlOperation(createQuestion, {input: question}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Create question Error: ', e);
        throw new Error(e);
      });

    return createQuestionResponse.data?.createQuestion.id;
  }
}
