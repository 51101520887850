import {IPlansRepository} from "./IPlansRepository";
import {API, graphqlOperation} from "aws-amplify";

import {plan} from "./PlansQueries";
import {updatePlan} from "./PlansMutation";

export class PlansRepository implements IPlansRepository {
  async getPlanById(planId: string): Promise<any> {
    let response: any = await API.graphql(graphqlOperation(plan, {id: planId})) // @ts-ignore
      .catch((e) => {
        console.log("Fetch plan Error: ", e);
      });
    return response.data?.plan;
  }

  async updatePlan(planId: string, updatedAt: string, plan: any): Promise<any> {
    let updatePlanResponse = await API.graphql(
      graphqlOperation(updatePlan, {
        id: planId,
        updatedAt: updatedAt,
        input: plan,
      }),
    );
    console.log("updatePlanResponse->", updatePlanResponse);
    return null;
  }
}
