import {IStudentsRepository} from './IStudentsRepository';
import {API, graphqlOperation} from 'aws-amplify';

import {Student} from '../../graphql/API';
import {students, student} from './StudentsQueries';

export class StudentsRepository implements IStudentsRepository {
  async getStudents(): Promise<Student | any> {
    try {
      let response = await API.graphql(graphqlOperation(students));
      // console.log('students from repository:', response);
      // @ts-ignore
      return response.data?.students.items;
    } catch (e) {
      console.log('getStudents Error->', e);
    }
  }
  async getStudentById(studentId: string): Promise<any> {
    let response: any = await API.graphql(
      graphqlOperation(student, {id: studentId}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Fetch student Error: ', e);
      });
    return response.data?.student;
  }
}
