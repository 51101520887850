import {API, graphqlOperation} from 'aws-amplify';

import {IAssetsRepository} from './IAssetsRepository';
import {createAsset} from './AssetsMutations';
import {Asset} from '../../graphql/API';

export class AssetsRepository implements IAssetsRepository {
  async createAsset(asset: Asset): Promise<any> {
    let createAssetResponse: any = await API.graphql(
      graphqlOperation(createAsset, {input: asset}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Create asset Error: ', e);
        throw new Error(e);
      });
    console.log(
      'createAssetResponse.data?.createAsset->',
      createAssetResponse.data?.createAsset,
    );
    return createAssetResponse.data?.createAsset.id;
  }
}
