import {API, graphqlOperation} from 'aws-amplify';

import {IEnrollmentsRepository} from './IEnrollmentsRepository';
import * as enrollmentQueries from './EnrollmentsQueries';
import {createEnrollment, updateEnrollment} from './EnrollmentsMutations';

export class EnrollmentsRepository implements IEnrollmentsRepository {
  async getEnrollments(): Promise<any> {
    try {
      let enrollments = await API.graphql(
        graphqlOperation(enrollmentQueries.enrollments),
      );
      console.log('Enrollments->', enrollments);
      // @ts-ignore
      return enrollments.data?.enrollments.items;
    } catch (e) {
      console.log('getEnrollments repository Error:', e);
    }
  }

  async getEnrollmentById(enrollmentId: string): Promise<any> {
    let enrollment: any = await API.graphql(
      graphqlOperation(enrollmentQueries.enrollment, {id: enrollmentId}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Fetch enrollment Error: ', e);
      });
    return enrollment.data?.enrollment;
  }

  async createEnrollment(enrollment: any): Promise<any> {
    let createEnrollmentResponse: any = await API.graphql(
      graphqlOperation(createEnrollment, {input: enrollment}),
    ) // @ts-ignore
      .catch((e) => {
        console.log('Fetch enrollment Error: ', e);
        throw new Error(e);
      });

    return createEnrollmentResponse.data?.createEnrollment;
  }

  async updateEnrollment(
    enrollmentId: string,
    updatedAt: string,
    enrollment: any,
  ): Promise<any> {
    let updateEnrollmentResponse = await API.graphql(
      graphqlOperation(updateEnrollment, {
        id: enrollmentId,
        updatedAt: updatedAt,
        input: enrollment,
      }),
    );
    console.log('updateEnrollmentResponse->', updateEnrollmentResponse);
    return updateEnrollmentResponse;
  }
}
