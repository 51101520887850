import {Button, Spin, Table} from 'antd';
import {StudentsService} from './StudentsService';
import {useEffect, useState} from 'react';
import {Gender, Student} from '../../graphql/API';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment from 'moment';

const studentsService = new StudentsService();
export const StudentsTable = () => {
  const [students, setStudents] = useState<Array<Student> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    studentsService
      .getStudents()
      .then((resp) => {
        setIsLoading(false);
        console.log('respt->', resp);
        setStudents(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
      render: (id: string) => <Link to={'/students/' + id}>{id}</Link>,
    },
    {
      title: 'Name',
      defaultSortOrder: 'ascend',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: (a: any, b: any) => a.gender.localeCompare(b.gender),
    },
    {
      title: 'Language',
      dataIndex: ['language', 'id'],
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];

  return (
    <>
      <Button
        size={'large'}
        style={{float: 'right', margin: 20}}
        onClick={() => alert('In progress')}>
        Create
      </Button>
      {!isError && !isLoading && (
        <Table
          expandRowByClick={true}
          onExpand={() => console.log('on!')}
          //@ts-ignore
          columns={columns}
          dataSource={students}
          onChange={onChange}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
