// TODO: add user to get student's parent
export const enrollments = `
  query Enrollments(
    $filter: EnrollmentsFilter
    $limit: Int
    $nextToken: String
  ) {
    enrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate      
        student {
        id
        age
        user {
        parent{
        id
        name
        }
        }
        name
        } 
      }
      nextToken
      scannedCount
    }
  }
`;

export const enrollment = `
 query enrollment ($id: String!) {
    enrollment (id: $id) {
        id
        createdAt
        updatedAt
        answer{
        id
        score
        audioUri
        }
        student {
            id
            createdAt
            updatedAt
            name
            birthDate
            age
            gender
            language {
                id
                createdAt
                updatedAt
                language
                country
            }
            notes
            facebookProfileUrl
            enrollments {
                id
                createdAt
                updatedAt
                student {
                    id
                    createdAt
                    updatedAt
                    name
                    birthDate
                    age
                    gender
                    notes
                    facebookProfileUrl
                    memberships {
                        id
                        createdAt
                        updatedAt
                    }
                }
                package {
                    id
                    createdAt
                    updatedAt
                    number
                    onlyAI
                    name
                    sessionDuration
                    program {
                        id
                        createdAt
                        updatedAt
                        name
                    }
                    price {
                        id
                        createdAt
                        updatedAt
                        name
                        price
                        currency
                        country
                    }
                    description
                    benefits
                    promoAsset {
                        id
                        createdAt
                        updatedAt
                        type
                        thumbnailUri
                        fileUri
                    }
                    coverAsset {
                        id
                        createdAt
                        updatedAt
                        type
                        thumbnailUri
                        fileUri
                    }
                }
                level {
                    id
                    createdAt
                    updatedAt
                    name
                    number
                }
                test {
                    id
                    createdAt
                    updatedAt
                    questions {
                        id
                        createdAt
                        updatedAt
                        name
                        choices
                        programType
                        keywords
                        summary
                        description
                        text
                    }
                    type
                    name
                    description
                    summary
                    asset {
                        id
                        createdAt
                        updatedAt
                        type
                        thumbnailUri
                        fileUri
                    }
                }
                placementStatus
                paymentStatus
                startDate
                slots {
                    id
                    createdAt
                    updatedAt
                    name
                    sessionDuration
                    availability {
                        id
                        createdAt
                        updatedAt
                        startDateTime
                        endDateTime
                    }
                    program {
                        id
                        createdAt
                        updatedAt
                        name
                    }
                    level {
                        id
                        createdAt
                        updatedAt
                        name
                        number
                    }
                    startDateTime
                    endDateTime
                    status
                    limit
                    current
                }
            }
        }
        package {
            id
            createdAt
            updatedAt
            number
            onlyAI
            name
            sessionDuration
            description
            benefits
        }
        level {
            id
            createdAt
            updatedAt
            name
            number
        }
        test {
            id
            name
        }
        placementStatus
        paymentStatus
        startDate
    }
}`;
