import {ILevelsService} from './ILevelsService';
import {LevelsRepository} from './LevelsRepository';
import {Level} from '../../graphql/API';

export class LevelsService implements ILevelsService {
  levelsRepository: LevelsRepository;
  constructor() {
    this.levelsRepository = new LevelsRepository();
  }

  async getLevels(): Promise<Array<Level> | any> {
    let levels = await this.levelsRepository.getLevels();
    // console.log('getLevels from service -->', levels);
    return levels;
  }
}
