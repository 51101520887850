import {IAssetsService} from './IAssetsService';
import {AssetsRepository} from './AssetsRepository';
import {Asset} from '../../graphql/API';

export class AssetsService implements IAssetsService {
  assetsRepository: AssetsRepository;
  constructor() {
    this.assetsRepository = new AssetsRepository();
  }

  async createAsset(asset: Asset): Promise<any> {
    return await this.assetsRepository.createAsset(asset);
  }
}
