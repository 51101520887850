import {IStudentsService} from './IStudentsService';
import {StudentsRepository} from './StudentsRepository';
import {Student} from '../../graphql/API';

export class StudentsService implements IStudentsService {
  studentsRepository: StudentsRepository;
  constructor() {
    this.studentsRepository = new StudentsRepository();
  }

  async getStudents(): Promise<any> {
    let stds = await this.studentsRepository.getStudents();
    //console.log('stds-->', stds);
    return stds;
  }
  async getStudentById(studentId: string): Promise<Student> {
    let student = await this.studentsRepository.getStudentById(studentId);
    console.log('student from service ->', student);
    return student;
  }
}
