import {IEnrollmentsService} from './IEnrollmentsService';
import {EnrollmentsRepository} from './EnrollmentsRepository';
import {Enrollment} from '../../graphql/API';

export class EnrollmentsService implements IEnrollmentsService {
  enrollmentsRepository: EnrollmentsRepository;
  constructor() {
    this.enrollmentsRepository = new EnrollmentsRepository();
  }

  async getEnrollments(): Promise<any> {
    let enrollments = await this.enrollmentsRepository.getEnrollments();
    console.log('enrollemnts from service -->', enrollments);
    return enrollments;
  }

  async getEnrollmentById(enrollmentId: string): Promise<Enrollment> {
    let enrollment = await this.enrollmentsRepository.getEnrollmentById(
      enrollmentId,
    );
    console.log('enrollment from service ->', enrollment);
    return enrollment;
  }

  async createEnrollment(enrollment: any): Promise<any> {
    return await this.enrollmentsRepository.createEnrollment(enrollment);
  }
  async updateEnrollment(
    enrollmentId: string,
    updatedAt: string,
    enrollment: any,
  ): Promise<any> {
    return await this.enrollmentsRepository.updateEnrollment(
      enrollmentId,
      updatedAt,
      enrollment,
    );
  }
}
