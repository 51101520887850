export const packages = `query packages ($filter: PackagesFilter, $limit: Int, $nextToken: String) {
    packages (filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            createdAt
            updatedAt
            number
            onlyAI
            name
            sessionDuration
            description
            benefits
        }
        nextToken
        scannedCount
    }
}
`;
