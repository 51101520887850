import {ITestsService} from './ITestsService';
import {TestsRepository} from './TestsRepository';
import {Test} from '../../graphql/API';
import {AssetsService} from '../Asset';
import {QuestionsService} from '../Questions';
import {validateCreateTest} from '../../utils/Validators';

export class TestsService implements ITestsService {
  testsRepository: TestsRepository;
  assetsService: AssetsService;
  questionsService: QuestionsService;
  constructor() {
    this.testsRepository = new TestsRepository();
    this.assetsService = new AssetsService();
    this.questionsService = new QuestionsService();
  }

  async getTests(): Promise<Array<Test> | any> {
    let tests = await this.testsRepository.getTests();
    return tests;
  }

  async getTestById(testId: string): Promise<Test> {
    return await this.testsRepository.getTestById(testId);
  }

  async createTest(test: Test | any): Promise<any> {
    try {
      let testObj = {
        name: test.name,
        assetId: '',
        questionIds: [],
        type: test.type,
        description: test.description,
        summary: test.summary,
      };
      console.log('inside create test');
      testObj.assetId = await this.assetsService.createAsset(test.asset!);
      testObj.questionIds = await Promise.all(
        test.questions?.map(async (q: any) => {
          return await this.questionsService.createQuestion(q);
        }),
      );
      //TODO: Add validators
      return await this.testsRepository.createTest(testObj);
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(`Creating test error: ${e}`),
      );
    }
  }
}
