import {API, graphqlOperation} from 'aws-amplify';

import {ILevelsRepository} from './ILevelsRepository';
import {levels} from './LevelsQueries';

export class LevelsRepository implements ILevelsRepository {
  async getLevels(): Promise<any> {
    try {
      let response = await API.graphql(graphqlOperation(levels));
      //  console.log('Levels->', response);
      // @ts-ignore
      return response.data?.levels.items;
    } catch (e) {
      console.log('getLevels repository Error:', e);
    }
  }
}
