export const createAsset = `
mutation createAsset ($input: AssetUpsert!) {
    createAsset (input: $input) {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
    }
}
`;
