import {useEffect, useState} from 'react';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import {Button, Col, DatePicker, Row, Select, Space, Typography} from 'antd';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
  Enrollment,
  Level,
  Package,
  Status,
  Student,
  Test,
} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {EnrollmentsService} from './EnrollmentsService';

const studentsService = new StudentsService();
const packagesService = new PackagesService();
const levelService = new LevelsService();
const testsService = new TestsService();
const enrollmentsService = new EnrollmentsService();

export const EnrollmentEdit = () => {
  const history = useHistory();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const {Title, Text} = Typography;
  const [students, setStudents] = useState<Array<Student> | []>([]);
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);

  let params: {enrollmentId: string} = useParams();
  const [enrollmentId, setEnrollmentId] = useState<string>('');
  const [updatedAt, setUpdatedAt] = useState<string>('');

  const [studentId, setStudentId] = useState<string | undefined>(undefined);
  const [packageId, setPackageId] = useState<string | undefined>(undefined);
  const [levelId, setLevelId] = useState<string | undefined>(undefined);
  const [testId, setTestId] = useState<string | undefined>(undefined);
  const [paymentStatus, setPaymentStatus] = useState<string | undefined>(
    undefined,
  );
  const [placementStatus, setPlacementStatus] = useState<string | undefined>(
    undefined,
  );
  const [startDate, setStartDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    testsService
      .getTests()
      .then((tsts) => {
        setTests(tsts);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    levelService
      .getLevels()
      .then((lvls) => {
        setLevels(lvls);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    packagesService
      .getPackages()
      .then((pkgs) => {
        setPackages(pkgs);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    studentsService
      .getStudents()
      .then((stds) => {
        // console.log('stds->', stds);
        setStudents(stds);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    /************ NEW */
    if (location.state.enrollment) {
      setEnrollmentId(location.state.enrollment.id);
      setUpdatedAt(location.state.enrollment.updatedAt);
      setStudentId(location.state.enrollment.student.id);
      setTestId(location.state.enrollment.test?.id);
      setPackageId(location.state.enrollment.package.id);
      setLevelId(location.state.enrollment.level?.id);
      setPlacementStatus(location.state.enrollment.placementStatus);
      setPaymentStatus(location.state.enrollment.paymentStatus);
      setStartDate(location.state.enrollment.startDate);
    } else {
      enrollmentsService
        .getEnrollmentById(params.enrollmentId)
        .then((enr: Enrollment) => {
          setUpdatedAt(enr?.updatedAt!);
          setEnrollmentId(enr?.id!);
          setStudentId(enr?.student?.id);
          setTestId(enr?.test?.id);
          setPackageId(enr?.package?.id);
          setLevelId(enr?.level?.id);
          setPlacementStatus(enr.placementStatus);
          setPaymentStatus(enr.paymentStatus);
          // @ts-ignore
          setStartDate(enr?.startDate);
        })
        .catch((e) => {
          setIsError(true);
        });
    }
  }, []);

  const onChange = (prop: any, value: any) => {
    /* let tempEnrollment = enrollmentObj;
    // @ts-ignore
    tempEnrollment[prop] = value;
    setEnrollmentObj(tempEnrollment);*/
  };
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit enrollment </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Student
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={studentId}
                showSearch
                style={{width: 200}}
                placeholder="Select a student"
                optionFilterProp="children"
                onChange={(stdId) => setStudentId(stdId)}>
                {students.map((std) => (
                  <Select.Option value={std.id!}>
                    {std.name} {std.user?.parent?.name} / {std.age}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={packageId}
                showSearch
                style={{width: 200}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(pkgId) => setPackageId(pkgId)}>
                {packages.map((pkg) => (
                  <Select.Option value={pkg.id!}>{pkg.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Level
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={levelId}
                showSearch
                style={{width: 200}}
                placeholder="Select a level"
                optionFilterProp="children"
                onChange={(lvlId) => setLevelId(lvlId)}>
                {levels.map((lvl) => (
                  <Select.Option value={lvl.id!}>{lvl.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Test
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={testId}
                showSearch
                style={{width: 200}}
                placeholder="Select a test"
                optionFilterProp="children"
                onChange={(tstId) => setTestId(tstId)}>
                {tests.map((tst) => {
                  if (tst.type === 'PLACEMENT_TEST') {
                    return (
                      <Select.Option value={tst.id!}>{tst.name}</Select.Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Placement status
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={placementStatus}
                showSearch
                style={{width: 200}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(placementSts) => setPlacementStatus(placementSts)}>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment status
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                value={paymentStatus}
                showSearch
                style={{width: 200}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(pymtStatus) => setPaymentStatus(pymtStatus)}>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Start date
              </Text>

              <Space direction="vertical">
                <DatePicker
                  clearIcon={null}
                  value={moment(startDate)}
                  onChange={(date, dateString) => setStartDate(dateString)}
                />
              </Space>
            </div>
          </Col>

          <Col span={12}></Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          let tempEnrollment = {
            studentId: studentId,
            packageId: packageId,
            levelId: levelId,
            testId: testId,
            paymentStatus: paymentStatus,
            placementStatus: placementStatus,
            startDate: startDate,
          };
          console.log('tempEnrollment->', tempEnrollment);
          // console.log('enr->', enrollment);
          await enrollmentsService
            .updateEnrollment(enrollmentId, updatedAt, tempEnrollment)
            .then((e) => {
              console.log('eee->', e);
              alert('Enrollment edited');
              history.push('/enrollments/' + enrollmentId);
            })
            .catch((err) => {
              console.log('upadate errpr ->', err);
            });
        }}>
        Edit
      </Button>
    </div>
  );
};
