import {Link, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Button, Menu} from 'antd';
import {Auth} from 'aws-amplify';
export const SiderComponent = () => {
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>(['students']);
  let history = useHistory();

  useEffect(() => {});
  return (
    <Menu
      onClick={(e) => {
        console.log('MENU clicked->', e);
      }}
      theme="dark"
      mode="horizontal"
      onChange={(e) => {
        console.log('e->', e);
      }}>
      <Menu.Item key="students">
        <Link to={'/students'}>Students</Link>
      </Menu.Item>
      <Menu.Item key="enrollments" itemScope={true}>
        <Link to={'/enrollments'}>Enrollments</Link>
      </Menu.Item>
      <Menu.Item key="plans" itemScope={true}>
        <Link to={'/plans'}>Plans</Link>
      </Menu.Item>
      <Menu.Item key="tests" itemScope={true}>
        <Link to={'/tests'}>Tests</Link>
      </Menu.Item>
      <Menu.Item style={{}} key="signOut" itemScope={true}>
        <Button
          onClick={async () => {
            await Auth.signOut();
            history.go(0);
          }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
};
