import {useEffect, useState} from 'react';
import Title from 'antd/es/typography/Title';
import {Button, Col, DatePicker, Row, Select, Space, Typography} from 'antd';
import {useHistory} from 'react-router-dom';
import {Level, Package, Status, Student, Test} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {EnrollmentsService} from './EnrollmentsService';

const studentsService = new StudentsService();
const packagesService = new PackagesService();
const levelService = new LevelsService();
const testsService = new TestsService();
const enrollmentsService = new EnrollmentsService();

export const EnrollmentCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const [students, setStudents] = useState<Array<Student> | []>([]);
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [enrollment, setEnrollment] = useState<{
    studentId: string;
    packageId: string;
    levelId: string;
    testId: string;
    placementStatus: Status;
    paymentStatus: Status;
    startDate: Date;
  }>({
    studentId: '',
    packageId: '',
    levelId: '',
    testId: '',
    placementStatus: Status.TODO,
    paymentStatus: Status.TODO,
    startDate: new Date(),
  });
  useEffect(() => {
    testsService
      .getTests()
      .then((tsts) => {
        setTests(tsts);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    levelService
      .getLevels()
      .then((lvls) => {
        setLevels(lvls);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    packagesService
      .getPackages()
      .then((pkgs) => {
        setPackages(pkgs);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    studentsService
      .getStudents()
      .then((stds) => {
        console.log('stds->', stds);
        setStudents(stds);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    let tempEnrollment = enrollment;
    // @ts-ignore
    tempEnrollment[prop] = value;
    setEnrollment(tempEnrollment);
  };
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create enrollment </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Student
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a student"
                optionFilterProp="children"
                onChange={(studentId) => onChange('studentId', studentId)}>
                {students.map((std) => (
                  <Select.Option value={std.id!}>{std.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(packageId) => onChange('packageId', packageId)}>
                {packages.map((pkg) => (
                  <Select.Option value={pkg.id!}>{pkg.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Level
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a level"
                optionFilterProp="children"
                onChange={(levelId) => onChange('levelId', levelId)}>
                {levels.map((lvl) => (
                  <Select.Option value={lvl.id!}>{lvl.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Test
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a test"
                optionFilterProp="children"
                onChange={(testId) => onChange('testId', testId)}>
                {tests.map((tst) => {
                  if (tst.type === 'PLACEMENT_TEST') {
                    return (
                      <Select.Option value={tst.id!}>{tst.name}</Select.Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Placement status
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(placementStatus) =>
                  onChange('placementStatus', placementStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment status
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: 200}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(paymentStatus) =>
                  onChange('paymentStatus', paymentStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Placement status
              </Text>

              <Space direction="vertical">
                <DatePicker
                  onChange={(date, dateString) => {
                    onChange('startDate', dateString);
                  }}
                />
              </Space>
            </div>
          </Col>

          <Col span={12}></Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          console.log('enr->', enrollment);
          await enrollmentsService.createEnrollment(enrollment).then(() => {
            alert('Enrollment created');
            history.push('/enrollments');
          });
        }}>
        Create
      </Button>
    </div>
  );
};
