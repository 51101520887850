import {IPackagesService} from './IPackagesService';
import {PackagesRepository} from './PackagesRepository';
import {Package} from '../../graphql/API';

export class PackagesService implements IPackagesService {
  packagesRepository: PackagesRepository;
  constructor() {
    this.packagesRepository = new PackagesRepository();
  }

  async getPackages(): Promise<Array<Package> | any> {
    let packages = await this.packagesRepository.getPackages();
    // console.log('getPackages from service -->', packages);
    return packages;
  }
}
