import React from 'react';
import 'antd/dist/antd.css';
//@ts-ignore
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import {withAuthenticator} from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import './App.css';
import {SiderComponent} from './components';
import {
  EnrollmentsTable,
  EnrollmentDetails,
  EnrollmentCreate,
  EnrollmentEdit,
  CoursesTable,
  StudentDetails,
  StudentsTable,
  TestDetails,
  TestsTable,
  Dashboard,
  PlanDetails,
  PlanEdit,
  TestCreate,
} from './modules';

Amplify.configure(awsconfig);

function App() {
  return (
    <>
      <Router>
        <SiderComponent />
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          {/** Enrollments routes*/}
          <Route exact path="/enrollments">
            <EnrollmentsTable />
          </Route>
          <Route path="/enrollments/create">
            <EnrollmentCreate />
          </Route>
          <Route exact path="/enrollments/:enrollmentId">
            <EnrollmentDetails />
          </Route>
          <Route exact path="/enrollments/:enrollmentId/edit">
            <EnrollmentEdit />
          </Route>
          {/** Enrollments routes*/}
          <Route path="/students">
            <StudentsTable />
          </Route>
          <Route exact path="/students/:studentId">
            <StudentDetails />
          </Route>

          {/** Plans routes*/}
          {/**
           I am using courses to get more information such as level and language,..
           If i used planTable, i will get only plan Id and days
           */}
          <Route exact path="/plans">
            <CoursesTable />
          </Route>
          <Route exact path="/plans/:planId">
            <PlanDetails />
          </Route>
          <Route exact path="/plans/:planId/edit">
            <PlanEdit />
          </Route>

          {/** Tests routes*/}

          <Route exact path="/tests">
            <TestsTable />
          </Route>
          <Route path="/tests/create">
            <TestCreate />
          </Route>
          <Route exact path="/tests/:testId">
            <TestDetails />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default withAuthenticator(App);
//export default App;
