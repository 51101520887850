import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';
import moment from 'moment';

import {Enrollment} from '../../graphql/API';
import {EnrollmentsService} from './EnrollmentsService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

const enrollmentsService = new EnrollmentsService();

export const EnrollmentsTable = () => {
  const [enrollments, setEnrollments] = useState<Array<Enrollment> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    enrollmentsService
      .getEnrollments()
      .then((resp) => {
        setIsLoading(false);
        console.log('getEnrollmentsr response ->->', resp);
        setEnrollments(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={'/enrollments/' + id}>{id}</Link>,
    },
    {
      title: 'Started at',
      dataIndex: ['startDate'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.startDate).unix() - moment(b.startDate).unix(),
    },

    {
      title: 'Created at',
      dataIndex: ['createdAt'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'PlacementStatus',
      dataIndex: 'placementStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.placementStatus.localeCompare(b.placementStatus);
      },
      render: (placementStatus: string) => (
        <p
          style={{
            color:
              placementStatus === 'DONE'
                ? 'green'
                : placementStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {placementStatus}
        </p>
      ),
    },
    {
      title: 'PaymentStatus',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.paymentStatus.localeCompare(b.paymentStatus);
      },
      render: (paymentStatus: string) => (
        <p
          style={{
            color:
              paymentStatus === 'DONE'
                ? 'green'
                : paymentStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {paymentStatus}
        </p>
      ),
    },
    {
      title: 'Student',
      dataIndex: ['student', 'name'],
      render: (studentName: string, record: Enrollment) => (
        <Text>
          {record.student?.name} {record.student?.user?.parent?.name} /{' '}
          {record.student?.age}
        </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.student.name.localeCompare(b.student.name);
      },
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/enrollments/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table columns={columns} dataSource={enrollments} onChange={onChange} />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
