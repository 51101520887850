import {IPlansService} from './IPlansService';
import {PlansRepository} from './PlansRepository';
import {Plan} from '../../graphql/API';

export class PlansService implements IPlansService {
  plansRepository: PlansRepository;
  constructor() {
    this.plansRepository = new PlansRepository();
  }

  async getPlanById(planId: string): Promise<Plan> {
    let plan = await this.plansRepository.getPlanById(planId);
    console.log('plan from service ->', plan);
    return plan;
  }

  async updatePlan(planId: string, updatedAt: string, plan: any): Promise<any> {
    console.log('planId->', planId);
    console.log('updatedAt->', updatedAt);
    console.log('plan->', plan);
    return await this.plansRepository.updatePlan(planId, updatedAt, plan);
  }
}
