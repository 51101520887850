export const levels = `
    query levels ($filter: LevelsFilter, $limit: Int, $nextToken: String) {
    levels (filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            createdAt
            updatedAt
            name
            number
        }
        nextToken
        scannedCount
    }
}
`;
