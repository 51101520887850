export const courses = /* GraphQL */ `
  query courses($filter: CoursesFilter, $limit: Int, $nextToken: String) {
    courses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        program {
          id
          name
        }
        language {
          id
          language
          country
        }
        level {
          id
          name
        }
        plan {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;
